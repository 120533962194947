import React, { useEffect, useState } from 'react'
import './pages/index.scss'
import { Redirect, Route, Switch, } from 'react-router-dom'
import loadingComponent from './utils/loadingComponent'
import UserContext from './components/Context'
import Ajax from './utils/Ajax'
import Layout from './components/Layout'


const Login = loadingComponent(() => import('./pages/user/login/login'));
const Register = loadingComponent(() => import('./pages/user/login/register'));
const User = loadingComponent(() => import('./pages/user/index'))
const Home = loadingComponent(() => import('./pages/home'))
const NotFound = loadingComponent(() => import('./pages/notFound'));
const Preview = loadingComponent(()=> import('./pages/home/preview'))
const Share = loadingComponent(()=> import('./pages/home/share'))
export default () => {
  const [globalValue, setglobalValue] = useState({
    userInfo: {
      loading: true
    }
  })
  useEffect(() => {
    Ajax.post('/api/user/getInfo').then(res => {
      if (res.code === 0) {
        const newVal = Object.assign({}, globalValue, {
          userInfo: { ...res.data, loading: false }
        })
        setglobalValue(newVal)
      } else {
        const newVal = Object.assign({}, globalValue, {
          userInfo: { loading: false }
        })
        setglobalValue(newVal)
      }
    })
  }, [])
  return (
    <UserContext.Provider value={{ globalValue, setglobalValue }}>
      <div className="App">
        <Layout>
        <Switch>
          {/* 首页 欢迎页 */}
          <Route path="/" component={Home} exact />
          {/* 地图参数配置 在user中 */}
          {/* <Route path="/editmap" component={EditMapParams} exact /> */}
          {/* 个人中心 */}
          <Route path="/user" component={User} />
          <Route path="/preview" component={Preview} />
          <Route path="/share" component={Share} />
          {/* 登陆 */}
          <Route path="/login" component={Login} exact />
          {/* 注册 */}
          <Route path="/register" component={Register} exact />
          {/* 404 */}
          <Route path="/404" component={NotFound} />
          <Redirect to="/404" />
        </Switch>
        </Layout>
      </div>
    </UserContext.Provider>
  );
}

import React,{PureComponent} from 'react'
import classnames from 'classnames'
import layer from '../hoc/layerTouchClose'
import './style.scss'
/**
 * 弹出提示框 直接调用 new Tips(children,{其他参数});
 * */
export default layer (class Tips extends PureComponent{
    static defaultProps={
        time:1500
    };
    componentDidMount(){
        const {remove,time,callback}=this.props;
        if(time){
            setTimeout(()=>{
                remove();
                callback&&callback()
            },time);
        }
    }
    render(){
        const {className,title,children}=this.props;
        return (
            <div className={classnames(className,"c-tips")}>
                <div className="c-tips__area">
                    <div className="c-tips__title">{title}</div>
                    <div className="c-tips__content">
                        {children}
                    </div>
                    <span className="c-tips__close icon-close"></span>
                </div>
            </div>
        );
    }
})

export const Loading = ({title}) => {
    return <div className='layer__inner loading_layer__inner'>
    <div className='loading-title'>
        <span className={'iconfont icon-loading'}></span>
        {title ? title : '数据加载中...'}
    </div>
    </div>
}
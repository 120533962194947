import React from 'react';
import { compose } from 'recompose';
import layer from './layer';
/**
 * 将一个组件变成一个可以直接new调用的弹出一个可点击空白关闭层的高阶组件
 * */
export default compose(
  layer,
  function(ComposedComponent) {
    return props => {
      const touchStartHandler = e => {
        e.preventDefault();
        props.remove();
      };
      return (
        <div>
          <div className="layer__cover_touch" onClick={touchStartHandler}></div>
          <ComposedComponent {...props} />
        </div>
      );
    };
  }
);

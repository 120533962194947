import React from 'react'
import {withRouter} from 'react-router-dom'
import './style.scss'

export default withRouter(({children}) => {
  return (
    <div className={'a-layout'}>
        <div className={"a-body"}>{children}</div>
    </div>
  )
});

import axios from 'axios';
import Tips from '../components/Tips'
import qs from 'qs';
import {getCache} from './Util'
/**
 * 发送ajax请求
 * */
axios.interceptors.request.use((config) => {
  if(getCache('token',false)){
    config.headers = Object.assign({}, config.headers, {
      'token': getCache('token',false),
      // 'Content-Type': 'application/json;charset=UTF-8'
    })
  }else{
    config.headers = Object.assign({}, config.headers, {
      // 'Content-Type': 'application/json;charset=UTF-8'
    })
  }

  return config;
});

axios.interceptors.response.use((response) => {
  if (response.status > 300) {
    new Tips('请求错误');
  }
  return response.data;
}, (err) => {
  new Tips('请求错误');
  return Promise.reject(err);
});

export default axios;


export function getAjaxError(data, defaultError) {
  var error = [];
  if (data.code === 0) {
    return '';
  }

  if (typeof data.data === 'object') {
    for (let key in data.data) {
      if (typeof data.data[key] === 'string') {
        error.push(data.data[key]);
      } else if (typeof data.data[key] === 'object') {
        for (let j in data.data[key]) {
          error.push(data.data[key][j]);
        }
      }
    }
  }

  if (error.length > 1) {
    error = error.map(function (msg, index) {
      return index + 1 + '、' + msg;
    });
  }
  return (
    error.join(' ') || data.msg || defaultError || '很抱歉，出错了，请重试~'
  );
}

import React from 'react';
import Ajax from './Ajax';

export default class Util {
  //转化成每3位用逗号分割的形式
  static formatThousand = number => {
    let temp = parseInt(number)
        .toString()
        .split('')
        .reverse(),
      result = '';
    for (let i = 0; i < temp.length; i++) {
      result +=
        temp[i] + ((i + 1) % 3 === 0 && i + 1 !== temp.length ? ',' : '');
    }
    return result
      .split('')
      .reverse()
      .join('');
  };
  //微信分享
  static wxShare = (shareObj, options = {}) => {
    let wx = window.wx,
      isWeixin =
        window.navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1;
    if (wx) {
      wx.isWeixin = isWeixin;
    } else {
      return;
    }

    shareObj = Object.assign(
      {},
      {
        title: '',
        logo: '',
        des: '',
        subtitle: ''
      },
      shareObj
    );
    wx.onMenuShareAll = () => {};
    if (isWeixin) {
      var jsApiList = [
        'hideOptionMenu',
        'showOptionMenu',
        'onMenuShareTimeline',
        'onMenuShareAppMessage',
        'onMenuShareQQ',
        'onMenuShareWeibo'
      ];
      wx.onMenuShareAll = (obj, custom) => {
        custom = custom || {};
        // 正常应该是 URL 切换后签名失效，重新做一次签名config即可，但是在微信中发现不可行！！！
        // 反而是每次页面切换后都使用第一次获取的签名再 config 一下就可以。
        // 此方法在微信开发者工具中失败，IOS 未测试。。。
        wx.config(Object.assign({}, wx._config));

        wx.showOptionMenu();
        jsApiList.forEach((method, i) => {
          if (method.indexOf('onMenuShare') > -1) {
            var param = Object.assign({}, obj, custom[method]);
            if (method === 'onMenuShareTimeline') {
              param.title = shareObj.desc;
            }
            if (method === 'onMenuShareWeibo') {
              param.title = shareObj.desc;
            }
            ['success', 'cancel'].forEach((prop, idx) => {
              param[prop] = param[prop] && param[prop].call(param, method);
            });
            wx[method](Object.assign({}, param));
          }
        });
      };
      // 获取微信签名
      Ajax.post(
        window.location.protocol + '//' + window.location.host + '/wxsignature',
        {
          url: shareObj.link
        }
      ).then(({ data }) => {
        var config = data.data;
        if (data.err === 0) {
          config.jsApiList = jsApiList.concat([]);
          ['appId', 'timestamp', 'nonceStr', 'signature'].forEach((prop, i) => {
            config[prop] = '' + (config[prop] || '');
          });
          // config.debug = true;
          // 传入true参数避免深层引用导致wx._config.wxJsApi改变
          wx._config = config;
          wx.config(Object.assign({}, config));
          wx.ready(function() {
            wx.onMenuShareAll({
              title: shareObj.title,
              imgUrl: shareObj.logo,
              desc: shareObj.desc,
              link: shareObj.link
            });
          });
        } else {
          // alert(JSON.stringify(data));
        }
      });
    }
  };
}

export function compose(...funcs) {
  return function(val) {
    return funcs.reverse().reduce((a, b) => b(a), val);
  };
}
export function toFixed2(num) {
  let result = parseFloat(num);
  if(result<0){
    return `${toFixed2(0-result)}`
  }
  if (isNaN(result)) {
    // console.log('传递价格参数错误，请检查！',num);
    return 0;
  }
  result = Math.round(num * 100) / 100;
  let s_x = result.toString();
  let pos_decimal = s_x.indexOf('.');
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += '.';
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += '0';
  }
  return s_x
}
// 只返回小数点后两位
export function toFixed(num) {
    let result = parseFloat(num);
    if(result<0){
      return `${toFixed(0-result)}`
    }
    if (isNaN(result)) {
      // console.log('传递价格参数错误，请检查！',num);
      return '-';
    }
    result = Math.round(num * 100) / 100;
    let s_x = result.toString();
    let pos_decimal = s_x.indexOf('.');
    if (pos_decimal < 0) {
      pos_decimal = s_x.length;
      s_x += '.';
    }
    while (s_x.length <= pos_decimal + 2) {
      s_x += '0';
    }
    const list = s_x.split('.')
    return list[1]
    return <span>{list[0]}.<span className={"back-idot"}>{list[1]}</span></span>;
}
export const formatThousand = number => {
  const dot = toFixed(number)
  let temp = parseInt(number)
      .toString()
      .split('')
      .reverse(),
    result = '';
  for (let i = 0; i < temp.length; i++) {
    result +=
      temp[i] + ((i + 1) % 3 === 0 && i + 1 !== temp.length ? ',' : '');
  }
  return result
    .split('')
    .reverse()
    .join('')+ '.' + dot;
};
export const persent = (number) => {
  let result = parseFloat(number)*100;
  return formatThousand(result) + '%'
}
export function setCache(key, value) {
  if (typeof value === 'object') {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
}

export function getCache(key, isObject = true) {
  let value = localStorage.getItem(key) || '';
  if (isObject) {
    if (!value) {
      return {};
    }
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = {};
    }
  }
  return value;
}

export function removeCache(key) {
  localStorage.removeItem(key);
}

export function getScrollTop() {
  return (
    document.documentElement.scrollTop ||
    document.body.scrollTop ||
    document.scrollingElement.scrollTop
  );
}

export function getScrollHeight() {
  return Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  );
}


export function getQueryString(name) {
  let result = window.location.search.match(
    new RegExp('[?&]' + name + '=([^&]+)', 'i')
  );
  return result === null || result.length < 1
    ? ''
    : decodeURIComponent(result[1]);
}
export function getShortName(name){
  if(name.length>5){
    return name.splice(0,5)+'...'
  }else{
    return name
  }

}

export function isWechat(){
   return window.navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1;

}

export function setCookie(name, value, exdays) {
  let cookie = name + "=" + value + ";";
  if (exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toGMTString();
    cookie = cookie + ' ' + expires;
  }
  console.log(cookie+"path=/")
  document.cookie = cookie+"path=/";
}
//判断是安卓还是iOS
export function isAndroid_ios(){
  let u = navigator.userAgent, app = navigator.appVersion;
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1; //android终端或者uc浏览器
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  return isAndroid == true ? true : false ;
}
//获取限购数量
export function getLimitNum (name){
  let maxNum=99;
  try {
    maxNum = parseInt(name.split('限购')[1].split('人')[0])
    console.log(maxNum)
  }catch (e) {
    console.log(e)
  }

  return maxNum
}
export function formatOrderDataFromUrl() {
  let channelGoods = [],
    generalGoods=[]
  try {
    channelGoods = JSON.parse(getQueryString('channelGoods'));
    generalGoods =JSON.parse(getQueryString('goods'))

  }catch (e) {
    console.log('url解析错误', window.location.href)
  }
  return {channelGoods, generalGoods}
}
export function isYindong() {
  const userAgent = navigator.userAgent.toLowerCase();//获取UA信息
  if(userAgent.indexOf("yindong") != -1){//判断ua中是否含有和app端约定好的标识yindong
    return true
  }
  return false
}

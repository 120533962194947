import React, { lazy, Suspense, Component } from 'react'

export default component => {
    const WrappendComponent = lazy(component)
    return class extends Component {
        static defaultProps = {
            loading: null
        }
        render() {
            const { loading, ...rest } = this.props
            return (
              <Suspense fallback={loading}>
                  <WrappendComponent {...rest}/>
              </Suspense>
            )
        }
    }
}
